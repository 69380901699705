import React from "react";
import { ArrowRightCircle } from "react-feather";
import { Link } from "react-router-dom";
import styles from "./NextPage.module.css";

const NextPage = ({ to, desc, heading }) => {
  return (
    <Link to={to} className={styles.root}>
      <div className="container">
        <h4 className={styles.desc}>{desc}</h4>
        <h2 className={styles.heading}>
          {heading} <ArrowRightCircle />
        </h2>
      </div>
    </Link>
  );
};

export default NextPage;

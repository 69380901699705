import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import NextPage from "../../components/NextPage/NextPage";
import PageHeader from "../../components/PageHeader/PageHeader";
import Projects from "../../components/Projects/Projects";
import { Helmet } from "react-helmet";
import styles from "./Home.module.css";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Проекты</title>
        <meta
          name="description"
          content="Разработка и поддержка сайтов, интернет-магазинов, CRM-систем в Нижнем Новгороде"
        />
        <meta
          name="og:title"
          content="Проекты – melissa.team – Свежие интернет-решения для вашего бизнеса"
        />
        <meta
          name="og:description"
          content="Разработка и поддержка сайтов, интернет-магазинов, CRM-систем в Нижнем Новгороде"
        />
      </Helmet>
      <PageHeader
        heading="Свежие интернет-решения для вашего бизнеса"
        desc="Разработка и поддержка сайтов, интернет-магазинов, CRM-систем"
      />
      <Projects />
      <NextPage to={"/team"} desc="Узнать больше о нас" heading="Команда" />
    </>
  );
};

export default Home;

import React, { useEffect } from "react";
import NextPage from "../../components/NextPage/NextPage";
import PageHeader from "../../components/PageHeader/PageHeader";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="container">
        <h1 style={{ fontSize: "12vh" }}>404</h1>
      </div>
      <PageHeader
        heading="Страница не найдена"
        desc="Это могло произойти по нескольким причинам: возможно страница была удалена; Вы перешли по неверной ссылке; адрес был введен c ошибкой."
      />
      <NextPage to={"/"} desc="Вернуться" heading="На главную" />
    </>
  );
};

export default NotFound;

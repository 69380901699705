import React, { useEffect, useState } from "react";
import styles from "./Copyright.module.css";

const Copyright = () => {
  const [year, setYear] = useState();

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, [year]);

  return (
    <footer className={styles.root}>
      <div className="container">
        &copy; {year} melissa.team. Свежие интернет-решения для вашего бизнеса
      </div>
    </footer>
  );
};

export default Copyright;

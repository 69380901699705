import React from "react";
import styles from "./Project.module.css";
import Placeholder from "../../img/placeholder.jpg";
import { ArrowRightCircle } from "react-feather";

const Project = ({ title, desc, link, image, className, delay }) => {
  return (
    <article
      className={`${className} ${styles.root}`}
      data-aos="fade-up"
      data-aos-delay={delay}
    >
      <a href={link} target="_blank" className={styles.imgBlock}>
        <img src={Placeholder} data-src={image} className="lazyload" />
        <ArrowRightCircle className={styles.arrow} />
      </a>
      <h3>
        <a href={link} target="_blank">
          {title}
        </a>
      </h3>
      <p className={styles.desc}>{desc}</p>
    </article>
  );
};

export default Project;

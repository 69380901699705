import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import Social from "../../components/Social/Social";
import Copyright from "../../components/Copyright/Copyright";
import { Helmet } from "react-helmet";

const Address = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <a
      href="https://yandex.ru/maps/47/nizhny-novgorod/house/alekseyevskaya_ulitsa_26/YE0YdgdjSUUDQFtsfX9weX5iYg==/?ll=44.003004%2C56.318217&z=17.38"
      target="_blank"
    >
      г. Нижний Новгород, <br />
      ул. Алексеевская, <br />
      д. 26, офис 328
    </a>
  );
};

const ContactsList = () => {
  return (
    <>
      <h4>
        <a href="tel:+78312660078">8 (831) 266-00-78</a>
      </h4>
      <h4>
        <a href="mailto:hello@melissa.team">hello@melissa.team</a>
      </h4>
      <p>Режим работы: Пн-Пт 9:00 &ndash; 18:00</p>
      <Social />
    </>
  );
};

const Contacts = () => {
  return (
    <>
      <Helmet>
        <title>Контакты</title>
        <meta
          name="og:title"
          content="Контакты – melissa.team – Свежие интернет-решения для вашего бизнеса"
        />
      </Helmet>
      <PageHeader heading={<Address />} desc={<ContactsList />} />
      <Copyright />
    </>
  );
};

export default Contacts;

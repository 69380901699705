import React, { useState, useEffect } from "react";
import * as contentful from "contentful";
import AOS from "aos";
import "aos/dist/aos.css";
import styles from "./Partners.module.css";

const client = contentful.createClient({
  space: "vcaxjsbvkhhl",
  accessToken: "8Xq6ven30d0cmRaaoU6TbABry2spNyTqakFbSsPAPcw",
});

const Partners = () => {
  const [partners, serPartners] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "partners",
      })
      .then((response) => serPartners(response.items))
      .catch(console.error);
  }, []);

  useEffect(() => {
    AOS.init({
      offset: 0,
    });
  }, []);

  return (
    <section>
      <div className="container">
        <h2>Клиенты и партнеры</h2>
        <div className={styles.items}>
          {partners
            .sort((a, b) => a.fields.order - b.fields.order)
            .map((item, key) => (
              <div
                className={item}
                key={key}
                data-aos="fade-up"
                data-aos-delay={100 + key * 50}
              >
                <img
                  data-src={item.fields.logo.fields.file.url}
                  alt={item.fields.title}
                  className={`${styles.logo} lazyload`}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;

import React, { useState, useEffect } from "react";
import * as contentful from "contentful";
import styles from "./Projects.module.css";
import Project from "../Project/Project";
import AOS from "aos";
import "aos/dist/aos.css";

const client = contentful.createClient({
  space: "vcaxjsbvkhhl",
  accessToken: "8Xq6ven30d0cmRaaoU6TbABry2spNyTqakFbSsPAPcw",
});

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "projects",
      })
      .then((response) => setProjects(response.items))
      .catch(console.error);
  }, []);

  useEffect(() => {
    AOS.init({
      offset: 0,
    });
  }, []);

  if (projects.length === 0) {
    return <div className={styles.loading}>Загрузка...</div>;
  } else {
    return (
      <div className="container">
        <div className={styles.root}>
          {projects
            .sort((a, b) => a.fields.order - b.fields.order)
            .map((item, key) => (
              <Project
                delay={key % 2 == 0 ? 0 : 100}
                className={styles.project}
                key={item.sys.id}
                title={item.fields.title}
                desc={item.fields.desc}
                link={item.fields.link}
                image={item.fields.image.fields.file.url}
              />
            ))}
        </div>
      </div>
    );
  }
};

export default Projects;

import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import Partners from "../../components/Partners/Partners";
import NextPage from "../../components/NextPage/NextPage";
import { Helmet } from "react-helmet";
import AOS from "aos";
import styles from "./Team.module.css";
import "aos/dist/aos.css";

const Team = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Helmet>
        <title>Команда</title>
        <meta
          name="description"
          content="Основной нашей философией является решение бизнес-задач наших клиентов на основе проведения аналитической работы, применения передовых технологий и создания уникального дизайна."
        />
        <meta
          name="og:title"
          content="Команда – melissa.team – Свежие интернет-решения для вашего бизнеса"
        />
        <meta
          name="og:description"
          content="Основной нашей философией является решение бизнес-задач наших клиентов на основе проведения аналитической работы, применения передовых технологий и создания уникального дизайна."
        />
      </Helmet>
      <PageHeader
        heading="Современный дизайн и чистый код. Ничего лишнего"
        desc="Основной нашей философией является решение бизнес-задач наших клиентов на основе проведения аналитической работы, применения передовых технологий и создания уникального дизайна."
      />
      <div className="container">
        <div className={styles.service}>
          <div data-aos="fade-up" data-aos-delay="150">
            <h3>Разработка</h3>
            <ul>
              <li>Сайты</li>
              <li>Интернет-магазины</li>
              <li>CRM-системы</li>
              <li>Геймификация интерфейсов</li>
            </ul>
          </div>
          <div data-aos="fade-up" data-aos-delay="200">
            <h3>Дизайн</h3>
            <ul>
              <li>Веб-дизайн</li>
              <li>UX/UI дизайн</li>
              <li>Фирменные стили</li>
              <li>Иллюстрации</li>
            </ul>
          </div>
          <div data-aos="fade-up" data-aos-delay="250">
            <h3>Поддержка</h3>
            <ul>
              <li>Домены и хостинг</li>
              <li>Доработка функционала</li>
              <li>Информационная поддержка</li>
            </ul>
          </div>
        </div>
      </div>
      <Partners />
      <NextPage
        to={"/contacts"}
        desc="Готовы к сотрудничеству?"
        heading="Связаться с нами"
      />
    </>
  );
};

export default Team;

import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Nav.module.css";

const Nav = ({ className }) => {
  return (
    <nav className={`${styles.root} ${className}`}>
      <ul className={styles.nav}>
        <li className={styles.item}>
          <NavLink to={"/"} exact className={styles.link}>
            Проекты
          </NavLink>
        </li>
        <li className={styles.item}>
          <NavLink to={"/team"} className={styles.link}>
            Команда
          </NavLink>
        </li>
        <li className={styles.item}>
          <NavLink to={"/contacts"} className={styles.link}>
            Контакты
          </NavLink>
        </li>
        {/* <li className={styles.item}>
          <a href="tel:+78312660078" className={styles.link}>
            8 (831) 266-00-78
          </a>
        </li> */}
        <li className={styles.item}>
          <a href="mailto:hello@melissa.team" className={styles.link}>
            hello@melissa.team
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;

import React, { useState } from "react";
import Logo from "../Logo/Logo";
import styles from "./Header.module.css";
import Nav from "../Nav/Nav";
// import MobileNav from "../MobileNav/MobileNav";

const Header = () => {
  const [nav, navToggle] = useState(false);

  return (
    <>
      <header className={`${styles.root} ${nav ? "open" : ""}`}>
        <Logo />
        <Nav className={styles.navHeader} />
        <button onClick={() => navToggle(!nav)} className={styles.navToggle}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </header>
      {/* <MobileNav /> */}
    </>
  );
};

export default Header;

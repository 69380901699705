import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header/Header";
import Home from "../pages/Home/Home";
import Contacts from "../pages/Contacts/Contacts";
import Team from "../pages/Team/Team";
import NotFound from "../pages/NotFound/NotFound";
import ogImage from "../img/og_image.jpg";

const App = () => {
  return (
    <>
      <Helmet
        titleTemplate="%s - melissa.team – Свежие интернет-решения для вашего бизнеса"
        defaultTitle="melissa.team – Свежие интернет-решения для вашего бизнеса"
      >
        <meta name="og:locale" content="ru_RU" />
        <meta name="og:type" content="website" />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:image" content={`https://melissa.team${ogImage}`} />
        <meta
          name="og:title"
          content="melissa.team – Свежие интернет-решения для вашего бизнеса"
        />
      </Helmet>
      <BrowserRouter>
        <Header />
        <main>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/team" component={Team} />
            <Route path="/contacts" component={Contacts} />

            <Route path="/*" component={NotFound} />
          </Switch>
        </main>
      </BrowserRouter>
    </>
  );
};

export default App;

import React, { useEffect } from "react";
import styles from "./PageHeader.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

const PageHeader = ({ heading, desc }) => {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="container">
      <div className={styles.root}>
        <h1 data-aos="fade-up">{heading}</h1>
        <div className={styles.desc} data-aos="fade-up" data-aos-delay="100">
          {desc}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;

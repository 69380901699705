import React from "react";
import { ExternalLink } from "react-feather";
import styles from "./Social.module.css";

const socialLinks = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/_melissa.team_/",
  },
  {
    title: "Behance",
    link: "https://www.behance.net/melissa-team",
  },
];

const Social = () => {
  return (
    <nav>
      <ul className={styles.list}>
        {socialLinks.map((item, key) => (
          <li key={key} className={styles.item}>
            <a href={item.link} className={styles.link} target="_blank">
              {item.title} <ExternalLink />
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Social;
